<template>
  <Layout>
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        <v-row class="py-1 px-5">
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" class="px-5" PANNELS>
                <v-card NotificationPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">people</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">جميع الحسابات</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">ادارة حسابات المنصة</div>
                          </v-col>
                          <v-col cols="12" ADD-USERS>
                            <v-btn color="success" @click="newDialog = true" :class="`${arabic?'arFont':'enFont'}`">
                              اضافة حساب
                            </v-btn>
                            <v-layout row justify-center>
                              <v-dialog v-model="newDialog" persistent max-width="600">
                                <v-card>
                                  <v-card-title :class="`${arabic?'arFont':'enFont'}`" style="font-size:22px;font-weight:bold;">
                                    اضافة حساب
                                  </v-card-title>
                                  <v-card-text>
                                    <v-form>
                                      <v-text-field 
                                        append-icon="business" 
                                        label="اسم المؤسسة" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.institutions_name">
                                      </v-text-field>
                                      <v-text-field
                                        label="الشعار"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        dense
                                        hide-details
                                        outlined
                                        :loading="uploadProgress"
                                        @click="pickFileImages()"
                                        v-model.trim="newAccount.logo"
                                      >
                                        <template v-slot:prepend>
                                          <v-tooltip top >
                                            <template v-slot:activator="{ on }">
                                              <v-btn small color="primary" :loading="uploadProgress" icon @click="pickFileImages()" v-on="on">
                                                <v-icon>
                                                  cloud
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span :class="`${arabic?'arFont':'enFont'}`">
                                            اضغط لرفع الشعار</span>
                                            <input
                                              type="file"
                                              style="display: none"
                                              ref="fileImages"
                                              accept="csv/*"
                                              @change="onFilePickedImages"
                                            >
                                          </v-tooltip>
                                        </template>
                                      </v-text-field>
                                      <v-btn small :class="`${arabic?'arFont':'enFont'} my-2`" @click="pickFileImages()" color="success">رفع الشعار</v-btn>
                                      <v-text-field 
                                        append-icon="person" 
                                        name="name" 
                                        label="اسم الموظف المسؤول" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.name">
                                      </v-text-field>
                                      <v-text-field 
                                        append-icon="email" 
                                        name="email" 
                                        :label="i18n.email" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.email">
                                      </v-text-field>
                                      <v-text-field 
                                        append-icon="phone" 
                                        name="phone" 
                                        label="رقم الهاتف" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.phone">
                                      </v-text-field>
                                      <v-text-field 
                                        label="العنوان" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.address">
                                      </v-text-field>
                                      <v-text-field 
                                        label="المدينة" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.city">
                                      </v-text-field>
                                      <v-autocomplete
                                        label="البلد" 
                                        type="text" 
                                        dense
                                        outlined
                                        item-value="name"
                                        item-text="name"
                                        :items="countries"
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.country">
                                      </v-autocomplete>
                                      <v-text-field 
                                        label="عدد التوكينز" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.tokens">
                                      </v-text-field>
                                      <v-text-field 
                                        append-icon="person" 
                                        name="name" 
                                        label="Subdomain" 
                                        type="text" 
                                        dense
                                        outlined
                                        @keypress.enter="addAccount()"
                                        :class="`${arabic?'arFont':'enFont'}`"
                                        v-model.trim="newAccount.subdomain">
                                      </v-text-field>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click.native="newDialog = false" :class="`${arabic?'arFont':'enFont'}`">
                                      الغاء
                                    </v-btn>
                                    <v-btn color="primary" text @click.native="addAccount()" :class="`${arabic?'arFont':'enFont'}`">
                                      اضافة
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-layout>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
                            <Paging 
                              :resourceSettings="resourceSettings" 
                              :resource="resource"
                              @loadResource="loadResource"
                              @pageByNumber="pageByNumber"
                            />
                          </v-col>
                          <v-col cols="12" :class="`${arabic?'arFont':'enFont'}`">
                            <v-expansion-panels focusable>
                              <v-expansion-panel v-for="(r, i) in resource.data" :key="'resource-'+i"  style="cursor:pointer;">
                                <v-expansion-panel-header>
                                  <v-row class="pa-0 align-center">
                                    <v-col cols="12" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 font-weight-bold`">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <span v-bind="attrs" v-on="on">{{r.name}}</span>
                                        </template>
                                        <div class="text-center">
                                          <div><img :src="r.image"></div>
                                          <div :class="`${arabic?'arFont':'enFont'}`">{{r.departments}}</div>
                                        </div>
                                      </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" md="2" :class="`${arabic?'arFont':'enFont'} pa-0 py-1`">
                                      <div style="font-size:11px;">{{r.email}}</div>
                                    </v-col>
                                    <v-col cols="12" md="3" :class="`${arabic?'arFont':'enFont'} pa-0 py-1 adminSwitch text-left`">
                                      <v-switch @change="updateResource(r)" 
                                        hide-details 
                                        :class="`${arabic?'arFont':'enFont'}`" 
                                        :label="`التنبيهات عبر الايميل`" v-model="r.notification_emails"></v-switch>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-divider class="pb-3"></v-divider>
                                  <v-row class="pa-0 align-center">
                                    <v-col cols="12" md="12" :class="`${arabic?'arFont':'enFont'} pa-1 adminSwitch text-left`">
                                      <v-tabs
                                        v-model="tab"
                                        background-color="secondary"
                                        centered
                                        dark
                                      >
                                        <v-tabs-slider></v-tabs-slider>
                                        <v-tab href="#tab-1" :class="`${arabic?'arFont':'enFont'}`">
                                          اداء الموظفين
                                        </v-tab>
                                      </v-tabs>
                                      <v-tabs-items v-model="tab">
                                        <v-tab-item :value="'tab-1'">
                                          <v-card flat>
                                            <v-card-text class="py-0">
                                              <v-row class="pa-0">
                                                
                                              </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-spacer></v-spacer>
                                              
                                            </v-card-actions>
                                          </v-card>
                                        </v-tab-item>
                                      </v-tabs-items>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import Search from '@/components/Search'
import Paging from '@/components/Paging'
import Notifications from '@/components/Notifications'
import { format, render, cancel, register } from 'timeago.js';
import { randomString } from '@/customFunction';
import { firebase, store, db, auth, storageRef } from '@/fb';
import countriesJson from '@/countries'
import axios from 'axios';
const headers = {'Accept': 'application/json'};
const fireapi = 'https://us-central1-asklytic.cloudfunctions.net/main';

export default {
  components: { Paging, Notifications, Layout },
  data() {
    return {
      loading:false,
      dialog:false,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      tab:null,
      transaction:{},
      newDialog:false,
      newAccount:{},
      uploadProgress:false,
      uploadFiled:null,
      file:null,
      countries:countriesJson,
    }
  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode != 45 && charCode != 46 &&
      !(charCode >= 48 && charCode <= 57)) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    async recordTransaction(r){
      this.loading = true
      this.transaction.account_id = r.id
      await this.$store.dispatch('postResource', {resource:'/transactions', value:this.transaction})
      this.loading = false
      this.transaction = {}
    },
    getFormat(d){
      return format(d)
    },
    filterObject(item, queryText, itemText) {
      let v = Object.values(item).filter(x=>typeof x == 'string')
      let a = false
      v.forEach(x=>{
        if(x.toLowerCase().includes(queryText.toLowerCase())) a = true
      })
      if(itemText && typeof itemText == 'string' && itemText.toLowerCase().includes(queryText.toLowerCase())) a = true
      return a
    },
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async pageByNumber(n){
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path, page:`?page=${n}` + this.buildFilter()})
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + `/search?page=${n}`, value:this.search})
        }
        this.loading = false
      })
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    buildFilter(){
      let filter = ``
      return filter
    },
    async filterLeads(){
      await this.loadResource()
    },
    firstLoad(){
      if(this.user) {
      }
    },
    async loadResource() {
      return new Promise(async (res, rej)=>{
        this.loading = true
        if(!this.search){
          this.resource = await this.$store.dispatch('getResource', {resource:this.$route.path + '?' + this.buildFilter()})
        } else {
          this.resource = await this.$store.dispatch('searchResource', {resource:this.$route.path + '/search', value:this.search})
        }
        this.loading = false
        res()
      })
    },
    async updateResource(r){
      return new Promise(async (res, rej)=>{
        this.loading = true
        // console.log(r)
        await this.$store.dispatch('putResource', {resource:this.$route.path + `/${r.id}`, value:r})
        this.loadResource()
        this.loading = false
      })
    },
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    },
    pickFileImages(name){
      // console.log(this.$refs)
      this.$refs.fileImages.click()
    },
    loadFileImages(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('images/J_B_E_' + randomString(16, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					  this.uploadProgress = true
          }, (error) =>{
          // console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
					this.uploadProgress = false
          this.newAccount.logo = downloadURL
          // console.log(downloadURL)
        });
      });
    },
    onFilePickedImages (e) {
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.loadFileImages(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } 
    },
    async addAccount() {
      return new Promise(async (res, rej)=>{
        console.log('add account')
        this.loading = true
        let r = await this.$store.dispatch('postResource', {resource:'/accounts', value:{...this.newAccount}})
        if(!r.error) {
          this.loading = false
          this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'success')
        } else {
          this.$emit('snackbarNotification', `${this.i18n.accountCreated}`, 'success')
        }
        this.loading = false;
        this.newDialog = false
        await this.loadResource()
        this.loading = false
      })
    }
  },
  computed:{
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
  },
  async created(){
    this.setResourceName()
    await this.loadResource()
  },
}
</script>

<style>

</style>